<template>
  <div>
    <!-- <vue-perfect-scrollbar
      v-once
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    > -->
    <!-- Account Notification -->

    <b-link
      v-for="notification in notifications"
      :key="notification.n_notificatoin_id"
    >
      <b-card>
        <b-media>
          <template #aside>
            <b-avatar
              class="badge-light-primary"
              size="36"
              :variant="notification.type"
            >
              <feather-icon
                :icon="getNotificatoinIcon(notification.notification_name)"
              />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span :class="getNotiColor(notification.notification_name)">
              {{ notification.notification_name }}
            </span>
          </p>
          <span class="notification-text">{{
            notification.notification_text
          }}</span>
        </b-media>
      </b-card>
    </b-link>

    <!-- System Notification Toggler -->
    <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">System Notifications</h6>
        <b-form-checkbox :checked="true" switch />
      </div> -->

    <!-- System Notifications -->
    <!-- <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar size="32" :variant="notification.type">
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    <!-- </vue-perfect-scrollbar> -->
    <b-row>
      <b-col class="text-center">
        <a href="/" >Back to Home</a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  BCard,
  BCardText,
  BMedia,
  BAvatar,
  BLink,
  BFormCheckbox,
  BRow,
  BCol
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import storageService from "@/apiServices/storageService";
import NotificationServices from "@/apiServices/NotificationServices";
import Ripple from "vue-ripple-directive";

export default {
  metaInfo: {
    title: "Notifications | Revivify",
    htmlAttrs: {
      lang: "en-US",
    },
  },
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BLink,
    BFormCheckbox,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      notifications: [],
    };
  },
  beforeMount() {
    this.loadAllNotification();
  },
  methods: {
    // loadUserAction() {
    //   const profileData = storageService.UserData.getUserProfile();
    //   let user = JSON.parse(profileData);
    //   this.user_full_name = user.user_firstname;
    //   if(user.user_role_id == 1){
    //     this.user_type = "SELLER";
    //   }else{
    //     this.user_type = "ADMIN";
    //   }
    //   console.log(user);
    // },

    loadAllNotification() {
      NotificationServices.getAllUsersNotifiaction()
        .then((response) => {
          this.notifications = response.data.data;
          console.log("notifications: ", this.notifications);
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error! in notifications", error);
        });
    },

    getNotificatoinIcon(type) {
      if (type == "Item is accept") {
        return "AlertCircleIcon";
      } else {
        return "AlertCircleIcon";
      }
    },

    getNotiColor(type) {
      if (type == "Item is accept") {
        return "font-weight-bolder text-black";
      } else if (type == "Item is rejected") {
        return "font-weight-bolder text-black";
      } else {
        return "font-weight-bolder text-black";
      }
    },
  },
};
</script>

<style>
.badge-light-primary {
  background-color: #e5d5bc !important;
}

[dir] .card {
  margin-bottom: 1rem !important;
}
</style>
